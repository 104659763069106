import React, { Component } from "react";
import { UserContext } from "../auth/providers";
import { signOut } from "../firebase/firebase";
import Cms from "./cms/Cms";
import Event from "./event/Event";

const menuItems = [
    // { id: 1, name: "Resources Upload" },
    { id: 2, name: "Event Slots" }
];

class Home extends Component {

    state = {
        menuItems: menuItems,
        activeMenu: menuItems[0],
        showLoader: false,
    }

    setActiveMenu = (event, menu) => {
        event.preventDefault();

        this.setState({ activeMenu: menu });
    }

    toggleLoader = (value) => {
        this.setState({
            showLoader: value
        })
    }

    logout = (event) => {
        if (event)
            event.preventDefault()
        signOut()
    }

    render() {
        return (
            <>
                <div className="wrapper">

                    <header className="header">
                        <h1 className="header__logo"><img src="assets/images/logo.png" alt="" /></h1>
                        <div className="logout_button" onClick={(e) => this.logout(e)}>LogOut</div>
                    </header>

                    <aside className="sidebar">
                        <ul className="sidebar-menu">
                            {
                                this.state.menuItems.map(menu =>
                                    <li key={menu.id} onClick={event => this.setActiveMenu(event, menu)}>
                                        <a className={this.state.activeMenu.id == menu.id ? "active" : ""} href="">{menu.name}</a>
                                    </li>
                                )
                            }
                        </ul>
                    </aside>

                    {
                        this.state.activeMenu.id == 1 &&
                        <Cms toggleLoader={this.toggleLoader}></Cms>
                    }

                    {
                        this.state.activeMenu.id == 2 &&
                        <Event toggleLoader={this.toggleLoader} ></Event>
                    }

                    {
                        this.state.showLoader &&
                        <div className="loaderContainer">
                            <img alt="loader" src="/assets/images/loader.gif" className="loaderImage" />
                        </div>
                    }
                </div>

            </>
        );
    }

}
Home.contextType = UserContext;

export default Home;
