import React, {Component, useContext} from "react";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import { UserContext }from "../auth/providers";
import Home from "../Home/Home";
import Login from "../auth/login/Login";

class Application extends Component {

    render() {
        let user = this.context;

        return (
            user ? <Home/> :
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Login/>
                        </Route>
                    </Switch>
                </Router>
        );
    }
}

Application.contextType = UserContext;


export default Application;
