import React, { Component } from "react";
import { UserContext } from "../../auth/providers";
import $ from 'jquery';
import { addItems, deleteItem, getItems, updateItem } from "../../firebase/firebase";
import { lang } from "moment";
import { Language } from "../../firebase/AppString";

const stallOptions = [{ label: 'Video Zone', value: 'avzone' }, { label: 'Library', value: 'library' },];
const typeOptions = [
    // { label: 'Images', value: 'images' }, 
{ label: 'Videos', value: 'videos' }, { label: 'PDF', value: 'pdf'}];

class Cms extends Component {

    stall = React.createRef();
    type = React.createRef();

    state = {
        stall: stallOptions[0].value,
        type: typeOptions[0].value,
        langType: Language.English,
        stallOptions: stallOptions,
        typeOptions: typeOptions,
        items: [],
        editId: null,
        file: null
    };

    componentDidMount() {

        $(this.stall.current).select2({ minimumResultsForSearch: -1 }).on('change', (event) => {
            event.preventDefault();
            this.setState({ stall: event.target.value });
            this.getItems();
        });

        $(this.type.current).select2({ minimumResultsForSearch: -1 }).on('change', (event) => {
            event.preventDefault();
            this.setState({ type: event.target.value });
            this.getItems();
        });

        this.getItems();
    }

    onFileSelected = (event, index) => {
        const file = event.dataTransfer ? event.dataTransfer.files[0] : event.currentTarget.files[0];
        let item = this.state.items[index];
        item.file = file;
    };

    getItems = () => {
        getItems(this.state.stall, this.state.type).then(result => {
            if (!result.exists) {
                this.setState({ items: [] });
                return;
            }
            const data = result.data()[this.state.type];
            let items = []
            try {
                items = result.data()[this.state.type + "Index"].map(val => {
                    data[val].disabled = true;
                    return data[val];
                });
            } catch (e) {
                console.log(e)
            }

            this.setState({ items: items });
            this.setState({ backup: items });
        });
    }

    saveItem = (event, index) => {
        event.preventDefault();
        let item = this.state.items[index];
        item.type = this.state.type;

        if (this.props.toggleLoader) {
            this.props.toggleLoader(true)
        }
        if (item.id == null) {
            addItems(this.state.stall, item).then(value => {
                this.getItems();
                if (this.props.toggleLoader) {
                    this.props.toggleLoader(false)
                }
            }).catch(() => {
                if (this.props.toggleLoader) {
                    this.props.toggleLoader(false)
                }
            });
        } else {
            updateItem(this.state.stall, item).then(value => {
                this.setState({ editId: '' });
                if (this.props.toggleLoader) {
                    this.props.toggleLoader(false)
                }
            }).catch(() => {
                if (this.props.toggleLoader) {
                    this.props.toggleLoader(false)
                }
            });;
        }

    }

    deleteItem = (event, index) => {
        event.preventDefault();
        let item = this.state.items[index];
        item.type = this.state.type;
        if (this.props.toggleLoader) {
            this.props.toggleLoader(true)
        }
        deleteItem(this.state.stall, item).then(value => {
            if (this.props.toggleLoader) {
                this.props.toggleLoader(false)
            }
            let item = this.state.items
            item.splice(index, 1);
            this.setState({ item: item });
        }).catch(() => {
            if (this.props.toggleLoader) {
                this.props.toggleLoader(false)
            }
        });;
    }

    editItem = (event, index) => {
        event.preventDefault();
        this.setState({ items: this.state.backup, editId: this.state.items[index].id });
    }

    cancelItem = (event, index) => {
        event.preventDefault();
        var items = this.state.items;
        if (items[index].id == null) {
            let item = this.state.items
            item.splice(index, 1);
            this.setState({ item: item });
        } else {
            this.setState({ items: this.state.backup, editId: '' });
        }
    }

    handleChange(index, dataType, value) {
        const newState = this.state.items.map((item, i) => {
            if (i == index) {
                return { ...item, [dataType]: value };
            }
            return item;
        });

        this.setState({
            items: newState
        });
    }

    addItem = (event) => {
        event.preventDefault();
        let newState = this.state.items;
        newState.push({});

        console.log(newState);
        this.setState({ items: newState });
    }

    handleDropDown = (event) => {
        console.log(event.target.name, event.target.value)
        this.setState({
            langType: event.target.value
        })
    }

    render() {
        return (<>
            <div className="main-container sidebar-active min-height-full">

                <div className="dashboard__header">
                    <h2 className="dashboard__header__title">SELECT YOUR ZONE and category TO<br />UPLOAD CONTENT</h2>
                    <div className="d-flex align-items-center">
                        <div className="custom-dropdown">
                            <select ref={this.stall} className="js-example-basic-single">
                                {
                                    this.state.stallOptions.map(option =>
                                        <option key={option.value} defaultValue={stallOptions == option.value}
                                            value={option.value}  >{option.label}</option>
                                    )
                                }
                            </select>

                        </div>
                        <div className="custom-dropdown mg-l30">
                            <select ref={this.type} className="js-example-basic-single">
                                {
                                    this.state.typeOptions.map(option =>
                                        <option key={option.value} defaultValue={typeOptions == option.value}
                                            value={option.value}>{option.label}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="admin-catalogue">
                    <form>
                        {
                            this.state.items &&
                            this.state.items.map((value, index) =>
                                <>
                                    {(this.state.type == "images" || this.state.type == "pdf") &&
                                        <div key={value.id} className="admin-catalogue__row">
                                            {
                                                value.link &&
                                                <div className="custom-file-input">
                                                    {/* <img src={value.link} /> */}
                                                    <img src={ this.state.type == "pdf" ? '/assets/images/pdfIcon.png' : value.link} />
                                                </div>
                                            }
                                            {
                                                this.state.editId == value.id &&
                                                <div className="custom-file-input">
                                                    <input type="file" onChange={event => this.onFileSelected(event, index)} />
                                            +Add {this.state.type == "images" ? "Image" : "PDF"}
                                                </div>
                                            }

                                            <div className="d-flex align-items-center w-100">
                                                <div className="form-group" style={{ maxWidth: "18rem", width: "100%" }}>
                                                    <input disabled={this.state.editId != value.id}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Add Title"
                                                        value={value.name}
                                                        onChange={event => this.handleChange(index, 'name', event.target.value)} />

                                                </div>
                                                <div className="form-group">
                                                    <input disabled={this.state.editId != value.id}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        value={value.description}
                                                        onChange={event => this.handleChange(index, 'description', event.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-btn">
                                                {
                                                    this.state.editId != value.id &&
                                                    <button className="btn btn-primary mg-b30"
                                                        onClick={event => this.editItem(event, index)}>Edit</button>
                                                }
                                                {
                                                    this.state.editId == value.id &&
                                                    <button className="btn btn-primary mg-b30"
                                                        onClick={event => this.saveItem(event, index)}>Save</button>
                                                }
                                                {
                                                    this.state.editId != value.id &&
                                                    <button className="btn btn-primary"
                                                        onClick={event => this.deleteItem(event, index)}>Delete
                                                </button>
                                                }
                                                {
                                                    this.state.editId == value.id &&
                                                    <button className="btn btn-primary mg-b30"
                                                        onClick={event => this.cancelItem(event, index)}>Cancel</button>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {this.state.type == "videos" &&
                                        <div key={value.id} className="admin-catalogue__row">
                                            <div className="form-group">
                                                <label className="form-label">Title*</label>
                                                <input disabled={this.state.editId != value.id}
                                                    type="text"
                                                    className="form-control"
                                                    value={value.name}
                                                    onChange={event => this.handleChange(index, 'name', event.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Description</label>
                                                <input disabled={this.state.editId != value.id}
                                                    type="text"
                                                    className="form-control"
                                                    value={value.description}
                                                    onChange={event => this.handleChange(index, 'description', event.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Video Link</label>
                                                <div className="d-flex">
                                                    <input disabled={this.state.editId != value.id}
                                                        type="text"
                                                        className="form-control"
                                                        value={value.link}
                                                        onChange={event => this.handleChange(index, 'link', event.target.value)} />
                                                    {
                                                        this.state.stall === 'avzone' &&
                                                        <>
                                                            <select name="Lang" id="langType"
                                                                onChange={event => this.handleChange(index, 'lang', event.target.value)}
                                                                value={value.lang}
                                                            >
                                                                <option value={Language.English}>English</option>
                                                                <option value={Language.Hindi}>Hindi</option>
                                                            </select>
                                                        </>
                                                    }
                                                </div>

                                            </div>
                                            <div className="form-btn">
                                                {
                                                    this.state.editId != value.id &&
                                                    <button className="btn btn-primary mg-b30"
                                                        onClick={event => this.editItem(event, index)}>Edit</button>
                                                }
                                                {
                                                    this.state.editId == value.id &&
                                                    <button className="btn btn-primary mg-b30"
                                                        onClick={event => this.saveItem(event, index)}>Save</button>
                                                }
                                                {
                                                    this.state.editId != value.id &&
                                                    <button className="btn btn-primary"
                                                        onClick={event => this.deleteItem(event, index)}>Delete
                                                </button>
                                                }
                                                {
                                                    this.state.editId == value.id &&
                                                    <button className="btn btn-primary mg-b30"
                                                        onClick={event => this.cancelItem(event, index)}>Cancel</button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                            )
                        }

                        <button className="btn btn-primary mg-b20" onClick={event => this.addItem(event)}>+Add
                        Content
                                </button>

                    </form>
                </div>

            </div>

        </>);
    }

}

Cms.contextType = UserContext;

export default Cms;
