import React, { Component, useContext } from "react";
import PropTypes from "prop-types";

class Time extends Component {

    state = {
      currentHour: 1,
      currentMinutes: 30,
      indicator: "AM"
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(this.props.time)
        {
            this.props.time.hour != null&& this.setState({ currentHour: this.props.time.hour });
            this.props.time.minute != null && this.setState({ currentMinutes : this.props.time.minute });
            this.props.time.indicator != null && this.setState({ indicator: this.props.time.indicator });
        }
    }

    onHourForward = () => {
        if(this.state.currentHour <= 11)
        {
            let currentHour = this.state.currentHour + 1;
            this.setState({currentHour: currentHour})
        }
    }

    onHourBackward = () => {
        if(this.state.currentHour >= 2)
        {
            let currentHour = this.state.currentHour-1;
            this.setState({currentHour: currentHour})
        }
    }

    onMinutesForward = () => {
        if(this.state.currentMinutes <= 58)
        {
            let currentMinutes = this.state.currentMinutes + 1;
            this.setState({currentMinutes: currentMinutes})
        }
    }

    onMinutesBackward = () => {
        if(this.state.currentMinutes >= 1)
        {
            let currentMinutes = this.state.currentMinutes-1;
            this.setState({currentMinutes: currentMinutes})
        }
    }


    onIndicatorBackward = () => {
        this.setState({indicator: "PM"})
    }

    onIndicatorForward = () => {
        this.setState({indicator: "AM"})
    }

    onSetTime = ()=> {
        const time = {hour: this.state.currentHour, minute: this.state.currentMinutes, indicator: this.state.indicator};
        if(this.props.onTimeUpdate){
            this.props.onTimeUpdate(time);
        }
    }

    onClose = (event)=> {
        event.preventDefault();

        const time = {hour: this.state.currentHour, minute: this.state.currentMinutes, indicator: this.state.indicator};

        if(this.props.onClose){
            this.props.onClose(time);
        }
    }
    render() {
        return (<>
            <div className="popover-modal">
                <div className="popover-modal__inner">

                    <div className="popover-modal__body">
                        <div className="time-clock">
                            <div className="time-clock__header">
                                <h2 className="time-clock__title">Select Time</h2>
                                <a href="" className="time-clock__close" onClick={event => this.onClose(event)}><i className="icon-close"></i></a>
                            </div>
                            <div className="time-clock__body">
                                <div className="time-clock__item__wrapper">
                                    <div className="time-clock__item">
                                        <button className="time-clock__btn" onClick={event => this.onHourBackward(event)}><i className="icon-angle-forward"></i></button>
                                        <span className="time-clock__item__title">{this.state.currentHour}</span>
                                        <button className="time-clock__btn" onClick={event => this.onHourForward(event)}><i className="icon-angle-back"></i></button>
                                    </div>
                                    <div className="time-clock__item">
                                        <button className="time-clock__btn" onClick={event => this.onMinutesBackward(event)}><i className="icon-angle-forward"></i></button>
                                        <span className="time-clock__item__title">{this.state.currentMinutes}</span>
                                        <button className="time-clock__btn" onClick={event => this.onMinutesForward(event)}><i className="icon-angle-back"></i></button>
                                    </div>
                                    <div className="time-clock__item">
                                        <button className="time-clock__btn" onClick={event => this.onIndicatorBackward(event)}><i className="icon-angle-forward"></i></button>
                                        <span className="time-clock__item__title">{this.state.indicator}</span>
                                        <button className="time-clock__btn" onClick={event => this.onIndicatorForward(event)}><i className="icon-angle-back"></i></button>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <button className="btn btn-dark" onClick={event => this.onSetTime()}>Set Time</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>);
    }

}

Time.propTypes = {
    onTimeUpdate: PropTypes.func,
    onClose: PropTypes.func,
    time: PropTypes.object,
}

export default Time;
