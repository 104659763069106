import firebase, { apps } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/storage"
import imageCompression from 'browser-image-compression';
import { AppString, ItemCategories } from "./AppString";
import { eachWeekendOfMonth } from "date-fns";
import Moment from "moment";
import XLSX from "xlsx";
import { ca } from "date-fns/locale";
import { object } from "prop-types";

let firebaseConfig = {
    apiKey: "AIzaSyAbEeJ9k-l-slkL-rrsttgQZpKX714-kZA",
    authDomain: "ultratech-event.firebaseapp.com",
    databaseURL: "https://ultratech-event.firebaseio.com",
    projectId: "ultratech-event",
    storageBucket: "ultratech-event.appspot.com",
    messagingSenderId: "494885983528",
    appId: "1:494885983528:web:676134714d71870f26ec1d",
    measurementId: "G-J6TY81CNPJ"
};
firebase.initializeApp(firebaseConfig);
window.firebaseInstance = firebase;

//#region export 

export const firebaseApp = firebase;

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const realDB = firebase.database();

export const analytics = firebase.analytics();

export const storage = firebase.storage();
//#endregion

//#region Auth Functions
export const signInWithId = (email, password, successCallback, errCallback) => {
    auth.signInWithEmailAndPassword(email, password).then(function (cred) {
        console.log(cred.user.email + " is logged in right now");
        // console.log(cred.user);
        successCallback(cred.user);
    }).catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("code: " + errorCode + " & ErrorMsg: " + errorMessage);
        errCallback(error);
    });
}

export const signOut = () => {
    auth.signOut().then(function () {
        // window.open("https://" + document.domain, "_self");
        window.location.reload();
        window.location.href = "/";
    });
};
//#endregion

//#region utility things
async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export const generatePushID = (function () {
    // Modeled after base64 web-safe chars, but ordered by ASCII.
    var PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

    // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
    var lastPushTime = 0;

    // We generate 72-bits of randomness which get turned into 12 characters and appended to the
    // timestamp to prevent collisions with other clients.  We store the last characters we
    // generated because in the event of a collision, we'll use those same characters except
    // "incremented" by one.
    var lastRandChars = [];

    return function () {
        var now = new Date().getTime();
        var duplicateTime = (now === lastPushTime);
        lastPushTime = now;

        var timeStampChars = new Array(8);
        for (var i = 7; i >= 0; i--) {
            timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
            // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
            now = Math.floor(now / 64);
        }
        if (now !== 0) throw new Error('We should have converted the entire timestamp.');

        var id = timeStampChars.join('');

        if (!duplicateTime) {
            for (i = 0; i < 12; i++) {
                lastRandChars[i] = Math.floor(Math.random() * 64);
            }
        } else {
            // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
            for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
                lastRandChars[i] = 0;
            }
            lastRandChars[i]++;
        }
        for (i = 0; i < 12; i++) {
            id += PUSH_CHARS.charAt(lastRandChars[i]);
        }
        if (id.length != 20) throw new Error('Length should be 20.');

        return id;
    };
})();

export const youtube_parser = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


export function getWeekEndsByWeek(date) {

    const dates = eachWeekendOfMonth(date);

    const groups = dates.reduce((acc, date) => {
        const yearWeek = `${Moment(date).year()}-${Moment(date).week()}`;
        if (!acc[yearWeek]) {
            acc[yearWeek] = [];
        }
        acc[yearWeek].push(date);
        return acc;
    }, {});

    return groups;
}
function weekendsInMonth(m, y) {
    var days = new Date(y, m, 0).getDate();
    var sundays = [8 - (new Date(m + '/01/' + y).getDay())];
    var saturdays = [7 - (new Date(m + '/01/' + y).getDay())];

    let weekObj = {};
    let weekCount = 1;
    weekObj[`week${weekCount}`] = {}
    weekObj[`week${weekCount}`].saturday = saturdays[0];
    weekObj[`week${weekCount}`].sunday = sundays[0];
    weekCount += 1;

    for (var i = sundays[0] + 7; i <= days; i += 7) {
        sundays.push(i);
        weekObj[`week${weekCount}`] = {}
        weekObj[`week${weekCount}`].sunday = sundays[weekCount - 1];
        weekCount += 1;
    }
    weekCount = 2;
    let j = saturdays[0] + 7;
    while (j <= days) {
        saturdays.push(j);
        if (!weekObj[`week${weekCount}`]) {
            weekObj[`week${weekCount}`] = {}
        }
        weekObj[`week${weekCount}`].saturday = saturdays[weekCount - 1];
        weekCount += 1;
        j += 7
    }
    console.log(weekObj)
    return sundays;
}

//year-month
function MonthFinder(startDate, endDate) {
    var start = startDate.split('-');
    var end = endDate.split('-');
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = {};
    for (var i = startYear; i <= endYear; i++) {
        var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
        for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
            var month = j + 1;
            var displayMonth = month < 10 ? '0' + month : month;
            dates[`${i}-${displayMonth}`] =
            {
                date: `${i}-${displayMonth}`,
                month: `${displayMonth}`,
                year: i,
                dateUI: `${monthNames[displayMonth - 1]} ${i}`
            }
        }
    }
    console.log(dates)
    return dates;
}
//#endregion

//#region Firebase Storage Functions
export const uploadCompressedImageFile = async (stallDetails, imageData, callback) => {
    return new Promise(async (resolve, reject) => {
        try {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 128,
                useWebWorker: true
            }
            const compressedFile = await imageCompression(imageData.file, options);
            imageData.file = compressedFile;
            const thumbUrl = await uploadImageFile(stallDetails, imageData);
            resolve(thumbUrl);
        } catch (error) {
            console.log("Thumbnail image upload error!!!");
            reject(error);
        }
    })

}
export const uploadImageFile = async (stallDetails, imageData) => {
    return new Promise((resolve, reject) => {
        let storageRef = storage.ref(`${stallDetails.id}/${imageData.name}`)
        let task = storageRef.put(imageData.file)
        task.on('state_changed', (snapshot) => {
            //Progress 
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log(progress)
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log(snapshot.state)
            }
        }, (err) => {
            //Error
            console.log("Image upload error!!!");
            console.log(err)
            reject(err);

        }, () => {
            //Complete
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // console.log('File available at', downloadURL);
                resolve(downloadURL)
            });
        })
    })

}

export const deleteImageFileUsingURL = async (fileUrl) => {
    return new Promise(async (resolve, reject) => {
        try {
            var ref = storage.refFromURL(fileUrl)
            await ref.delete()
            // console.log("deletedFile!!")
            resolve()
        } catch (err) {
            //reject(err)
            console.log("Unable to delete file");
            console.log(err);
        }
    })
}
//#endregion

// slotDetails{ id: '', eventStartTime: '', active: ''}
export const updateSlot = (slotDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            let docRef = firestore.collection(AppString.SLOTSCOLLECTION).doc(`${slotDetails.id}`)
            await firestore.runTransaction(async (transcation) => {
                const doc = await transcation.get(docRef);
                if (!doc.exists) {
                    let error = {
                        code: 'NoSuchSlotFOund', message: 'nosoltfound'
                    }
                    throw (error)
                }
                const currentSlotData = doc.data();
                let updateSlotInfo = {
                    ...currentSlotData,
                    eventStartTime: slotDetails.eventStartTime ? slotDetails.eventStartTime : currentSlotData.eventStartTime,
                    active: slotDetails.active != null ? slotDetails.active : currentSlotData.active,
                }
                transcation.update(docRef, updateSlotInfo)
                return
            })
            resolve();
        }
        catch (err) {
            console.log(err)
            reject(err)
        }
    });
}

//#region Analytics
export const exportFile = (data, sheetName, filename) => {
    const wb = XLSX.utils.book_new()
    const wsAll = XLSX.utils.json_to_sheet(data)
    XLSX.utils.book_append_sheet(wb, wsAll, sheetName)
    XLSX.writeFile(wb, filename)
}

export const getAllFeedback = () => {
    return new Promise(async (res, rej) => {
        try {
            let path = "/feedback";
            let ref = realDB.ref(path);
            const re = await ref.once('value')
            if (re.exists()) {
                res(re.val());
            }
        } catch (err) {
            rej(err)
        }
    })
}

export const getEventFeedback = (eventId) => {
    return new Promise(async (res, rej) => {
        try {
            let path = 'feedback/' + eventId.toLowerCase();
            var userCheck = realDB.ref(path)
            const snapshot = await userCheck.once('value');
            if (snapshot.exists()) {
                let data = { ...snapshot.val() }
                Object.keys(data).forEach(userId => {
                    data[userId]['mobileNumber'] = userId
                });
                res(data)
            } else {
                let er = {
                    code: 'noDataFound',
                    message: 'noFeedbackDataFound'
                }
                throw er;
            }
        } catch (error) {
            rej(error)
        }
    })
}

export const getEventPaddavFeedback = (eventId) => {
    return new Promise(async (res, rej) => {
        try {
            let path = 'padavFeedback/' + eventId.toLowerCase();
            var userCheck = realDB.ref(path)
            const snapshot = await userCheck.once('value');
            if (snapshot.exists()) {
                let data = { ...snapshot.val() }
                Object.keys(data).forEach(userId => {
                    data[userId]['mobileNumber'] = userId
                });
                res(data)
            } else {
                let er = {
                    code: 'noDataFound',
                    message: 'noFeedbackDataFound'
                }
                throw er;
            }
        } catch (error) {
            rej(error)
        }
    })
}

export const getUserAnalyticsFeedback = (eventId) => {
    return new Promise(async (res, rej) => {
        try {
            let path = 'user_analytics/' + eventId.toLowerCase();
            var userCheck = realDB.ref(path)
            const snapshot = await userCheck.once('value');
            if (snapshot.exists()) {
                let data = { ...snapshot.val() }
                Object.keys(data).forEach(userId => {
                    if (data[userId].hasOwnProperty('firstLoginTime')) {
                        data[userId]['firstLoginTime'] = `${new Date(data[userId]['firstLoginTime']).toDateString()} , ${new Date(data[userId]['firstLoginTime']).toTimeString()}`
                    }
                    if (data[userId].hasOwnProperty('lastLoginTime')) {
                        data[userId]['lastLoginTime'] = `${new Date(data[userId]['lastLoginTime']).toDateString()} , ${new Date(data[userId]['lastLoginTime']).toTimeString()}`

                    }
                    if (data[userId].hasOwnProperty('lastLogoutTime')) {
                        data[userId]['lastLogoutTime'] = `${new Date(data[userId]['lastLogoutTime']).toDateString()} , ${new Date(data[userId]['lastLogoutTime']).toTimeString()}`
                    }
                });
                res(data)
            } else {
                let er = {
                    code: 'noDataFound',
                    message: 'noUserDataFound'
                }
                throw er;
            }
        } catch (error) {
            rej(error)
        }
    })
}


export const getRegisteredUserDataListener = (eventId, callback) => {
    try {
        if (!eventId) {
            let err = {
                code: "InvalidEventId", message: "InvalidEventId."
            }
            callback(err);
        }

        const docRef = firestore.collection(AppString.USERCOLLECTION).where('slot', '==', `${eventId}`);
        docRef.onSnapshot((query) => {
            if (query.empty) {
                let err = {
                    code: "NoRoomFound", message: "No ChatRoom room found for the event."
                }
                callback(err);
            }
            let data = {};
            const docs = query.docs;
            docs.forEach(element => {
                const dataObject = element.data();
                Object.keys(dataObject).forEach(key => {
                    if (typeof (dataObject[key]) === 'object') {
                        if (key === 'registrationTimeStamp') {
                            var dateObject = new Date(0);
                            dateObject.setUTCSeconds(dataObject[`${key}`].seconds);
                            if (data[`${element.id}`]) {
                                data[`${element.id}`][key] = `${dateObject.toDateString()} , ${dateObject.toTimeString()}`
                            } else {
                                data[`${element.id}`] = {
                                    [key]: `${dateObject.toDateString()} , ${dateObject.toTimeString()}`
                                }
                            }
                        } else {
                            Object.keys(dataObject[key]).forEach(subKey => {
                                if (data[`${element.id}`]) {
                                    data[`${element.id}`][`${key}_${subKey}`] = dataObject[key][subKey];
                                } else {
                                    data[`${element.id}`] = {
                                        [`${key}_${subKey}`]: dataObject[key][subKey]
                                    };

                                }
                            })
                        }
                    } else {
                        if (data[`${element.id}`]) {
                            data[`${element.id}`][key] = dataObject[key]
                        } else {
                            data[`${element.id}`] = {
                                [key]: dataObject[key]
                            }
                        }
                    }
                })
            });
            callback(null, data);
        })
    } catch (err) {
        console.log(err)
        callback(err);
    }
}


export const getRegisteredUserData = (eventId) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!eventId) {
                let err = {
                    code: "InvalidEventId", message: "InvalidEventId."
                }
                throw err;
            }
            const docRef = firestore.collection(AppString.USERCOLLECTION).where('slot', '==', `${eventId}`);
            const query = await docRef.get();
            // console.log(query)
            if (query.empty) {
                let err = {
                    code: "NoRoomFound", message: "No ChatRoom room found for the event."
                }
                throw err;
            }
            let data = {};
            const docs = query.docs;
            docs.forEach(element => {
                const dataObject = element.data();
                Object.keys(dataObject).forEach(key => {
                    if (typeof (dataObject[key]) === 'object') {
                        if (key === 'registrationTimeStamp') {
                            var dateObject = new Date(0);
                            dateObject.setUTCSeconds(dataObject[`${key}`].seconds);
                            if (data[`${element.id}`]) {
                                data[`${element.id}`][key] = `${dateObject.toDateString()} , ${dateObject.toTimeString()}`
                            } else {
                                data[`${element.id}`] = {
                                    [key]: `${dateObject.toDateString()} , ${dateObject.toTimeString()}`
                                }
                            }
                        } else {
                            Object.keys(dataObject[key]).forEach(subKey => {
                                if (data[`${element.id}`]) {
                                    data[`${element.id}`][`${key}_${subKey}`] = dataObject[key][subKey];
                                } else {
                                    data[`${element.id}`] = {
                                        [`${key}_${subKey}`]: dataObject[key][subKey]
                                    };

                                }
                            })
                        }
                    } else {
                        if (data[`${element.id}`]) {
                            data[`${element.id}`][key] = dataObject[key]
                        } else {
                            data[`${element.id}`] = {
                                [key]: dataObject[key]
                            }
                        }
                    }
                })
            });
            // console.log(data)
            resolve(data);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    })
}

export const getEventLoginData = (eventId, callback) => {
    let path = 'loggedInUser/' + eventId.toLowerCase();
    var userCheck = realDB.ref(path)
    userCheck.on('value', (snapshot) => {
        if (snapshot.exists()) {
            callback(null, snapshot.val())
        } else {
            let er = {
                code: 'noDataFound',
                message: 'noEventDataLoggedTillNow'
            }
            if (callback)
                callback(er)
        }
    }, (err) => {
        if (callback)
            callback(err)
    })
}
//#endregion

//#region Slot function
export const addEventSlot = (slotDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            slotDetails.month += 1;//index of month is coming
            let timestamp = new Date(`${slotDetails.month} ${slotDetails.day}, ${slotDetails.year} ${slotDetails.hour}:${slotDetails.min}:00`);
            timestamp = timestamp.getTime();
            let dateTimestamp = new Date(`${slotDetails.month} ${slotDetails.day}, ${slotDetails.year} 00:00:00`);
            dateTimestamp = dateTimestamp.getTime();
            let monthTimestamp = new Date(`${slotDetails.month} 1, ${slotDetails.year} 00:00:00`);
            monthTimestamp = monthTimestamp.getTime();

            let eventName = slotDetails.eventName ? slotDetails.eventName.toLowerCase() : 'event1'
            let slotInfo =
            {
                eventStartTime: timestamp,
                active: slotDetails.active !== undefined ? slotDetails.active : true,
                dayActive: true,
                registrationLimit: slotDetails.registrationLimit ? slotDetails.registrationLimit : 300,
                dateTimestamp: dateTimestamp,
                monthTimestamp: monthTimestamp,
                id: `${dateTimestamp}-${eventName}`,
                gotFull: false,
                duration: 120,//in min,
                weekId: slotDetails.weekId
            }
            console.log(`${dateTimestamp}-${eventName}`)
            let docRef = firestore.collection(AppString.SLOTSCOLLECTION).doc(`${dateTimestamp}-${eventName}`)
            await firestore.runTransaction(async (transcation) => {
                const doc = await transcation.get(docRef);
                //check with other slots for time difference
                if (!doc.exists) {
                    transcation.set(docRef, slotInfo)
                    return;
                }
                const currentSlotData = doc.data();
                let updateSlotInfo = {
                    eventStartTime: timestamp,
                    active: slotDetails.active !== undefined ? slotDetails.active : currentSlotData.active,
                    dayActive: slotDetails.dayActive !== undefined ? slotDetails.dayActive : currentSlotData.dayActive,
                    registrationLimit: slotDetails.registrationLimit !== undefined ? slotDetails.registrationLimit : currentSlotData.registrationLimit,
                }
                // if (currentSlotData.registeredUser)
                //     if (slotDetails.registrationLimit < Object.keys(currentSlotData.registeredUser).length) {
                //         updateSlotInfo.gotFull = true;
                //     }

                transcation.update(docRef, updateSlotInfo)
                return
            })
            resolve();
        } catch (err) {
            console.log(err)
            reject(err)
        }
    });
}

export const addDaySlot = (dayDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            let promiseArray = [addEventSlot({ eventName: 'event1', registrationLimit: 300, active: false, month: dayDetails.month, day: dayDetails.day, year: dayDetails.year, hour: 13, min: 0, weekId: dayDetails.weekId }),
            addEventSlot({ eventName: 'event2', registrationLimit: 300, active: false, month: dayDetails.month, day: dayDetails.day, year: dayDetails.year, hour: 16, min: 30, weekId: dayDetails.weekId }),
            addEventSlot({ eventName: 'event3', registrationLimit: 300, active: false, month: dayDetails.month, day: dayDetails.day, year: dayDetails.year, hour: 20, min: 0, weekId: dayDetails.weekId })]
            await Promise.all(promiseArray);
            resolve();
        } catch (err) {
            console.log(err)
            reject(err);
        }
    })
}
export const getSlots = (weekIds) => {
    return new Promise(async (res, rej) => {
        try {
            const ref = firestore.collection("eventSlots").where("weekId", "in", weekIds)
            const querySnapshot = await ref.get();
            if (!querySnapshot.empty) {
                res(querySnapshot.docs.map(value => value.data()));
            } else {
                rej({
                    code: "noDocFound", message: "No Doc FOund "
                })
            }
        } catch (error) {
            rej(error)
        }
    })
}

export const addWeekSlot = (monthDetails, weekDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            let promiseArray = []
            Object.keys(weekDetails).map(day => {
                promiseArray.push(addDaySlot({ month: monthDetails.month, day: weekDetails[day], year: monthDetails.year, weekId: monthDetails.weekId }))
            })
            await Promise.all(promiseArray);
            resolve();
        } catch (err) {
            console.log(err)
            reject(err);
        }
    })
}

export const toggleEventSlot = (slotId, toggleValue) => {
    return new Promise(async (resolve, reject) => {
        try {

            let docRef = firestore.collection(AppString.SLOTSCOLLECTION).doc(`${slotId}`)
            await docRef.update({
                active: toggleValue
            });
            resolve();
        } catch (err) {
            console.log(err)
            reject(err)
        }
    });
}

export const toggleSingleDateSlot = (slotId, toggleValue) => {
    return new Promise(async (resolve, reject) => {
        try {

            let docRef = firestore.collection(AppString.SLOTSCOLLECTION).doc(`${slotId}`)
            await docRef.update({
                dayActive: toggleValue
            });
            resolve();
        } catch (err) {
            console.log(err)
            reject(err)
        }
    });
}

export const toggleDateSlots = (dateTimestamp, toggleValue) => {
    return new Promise(async (resolve, reject) => {
        try {
            let promiseArray = [toggleSingleDateSlot(`${dateTimestamp}-event1`, toggleValue),
            toggleSingleDateSlot(`${dateTimestamp}-event2`, toggleValue),
            toggleSingleDateSlot(`${dateTimestamp}-event3`, toggleValue),]
            await Promise.all(promiseArray);
            resolve();
        } catch (err) {
            console.log(err)
            reject(err);
        }
    })
}
//#endregion
export const getItems = (stallId, type) => {
    let docRef = firestore.collection(AppString.BACKSTAGE).doc(`${stallId}`);

    let getOptions = {
        source: 'server'
    };

    return docRef.get(getOptions)
}

//#region Item Function
export const addItems = (stallId, itemDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (stallId) {
                stallId = stallId.toLowerCase();
            }
            console.log(stallId, itemDetails)
            let docRef = firestore.collection(AppString.BACKSTAGE).doc(`${stallId}`)
            await firestore.runTransaction(async (transcation) => {
                const doc = await transcation.get(docRef);
                let uniqueId = generatePushID();
                if (itemDetails.type === ItemCategories.IMAGES || itemDetails.type === ItemCategories.PDF) {
                    if (!itemDetails.file) {
                        let error =
                        {
                            code: "ItemFileNotPassed",
                            message: "No File is selected from userEnd"
                        }
                        throw error;
                    }
                    var fileData = {
                        name: `${itemDetails.name}_${Date.now()}`,
                        file: itemDetails.file
                    }
                    itemDetails.link = await uploadImageFile({ id: `${stallId}/${itemDetails.type}` }, fileData)
                    //upload and get link
                    //put link in itemDetailsObject
                } else if (itemDetails.type === ItemCategories.VIDEOS) {
                    itemDetails.videoCode = youtube_parser(itemDetails.link)
                }
                let docData = {}
                if (doc.exists) {
                    docData = doc.data();
                }
                if (!docData[`${itemDetails.type}`]) {
                    docData[`${itemDetails.type}`] = {}
                }
                if (!docData[`${itemDetails.type}Index`]) {
                    docData[`${itemDetails.type}Index`] = []
                }
                docData[`${itemDetails.type}`][uniqueId] =
                {
                    name: itemDetails.name,
                    id: uniqueId,
                    link: itemDetails.link,
                    description: itemDetails.description
                }
                docData[`${itemDetails.type}Index`].push(uniqueId);
                if (itemDetails.type === ItemCategories.VIDEOS) {
                    docData[`${itemDetails.type}`][uniqueId].videoCode = itemDetails.videoCode
                }
                if (itemDetails.lang) {
                    docData[`${itemDetails.type}`][uniqueId].lang = itemDetails.lang
                }
                // console.log(itemDetails.lang)
                doc.exists ? transcation.update(docRef, docData) : transcation.set(docRef, docData);
                return
            })
            resolve();
        } catch (err) {
            console.log(err)
            reject(err)
        }
    });
}

export const updateItem = (stallId, itemDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (stallId) {
                stallId = stallId.toLowerCase();
            }
            let docRef = firestore.collection(AppString.BACKSTAGE).doc(`${stallId}`)
            await firestore.runTransaction(async (transcation) => {
                const doc = await transcation.get(docRef);
                let docData = {}
                if (doc.exists) {
                    docData = doc.data();
                }
                let uniqueId = itemDetails.id;
                if (!docData[`${itemDetails.type}`]) {
                    let error =
                    {
                        code: "ItemTypeNotFoud",
                        message: "Such Item Doesn't Exists"
                    }
                    throw error;
                }
                if (!docData[`${itemDetails.type}`][uniqueId]) {
                    let error =
                    {
                        code: "ItemNotFoud",
                        message: "Such Item Doesn't Exists"
                    }
                    throw error;
                }
                if (itemDetails.type === ItemCategories.IMAGES || itemDetails.type === ItemCategories.PDF) {
                    if (itemDetails.file) {
                        await deleteImageFileUsingURL(docData[`${itemDetails.type}`][uniqueId].link)
                        var fileData = {
                            name: `${itemDetails.name}_${Date.now()}`,
                            file: itemDetails.file
                        }
                        itemDetails.link = await uploadImageFile({ id: `${stallId}/${itemDetails.type}` }, fileData)
                    }
                } else if (itemDetails.type === ItemCategories.VIDEOS) {
                    if (docData[`${itemDetails.type}`][uniqueId].link !== itemDetails.link)
                        itemDetails.videoCode = youtube_parser(itemDetails.link)
                    else
                        itemDetails.videoCode = docData[`${itemDetails.type}`][uniqueId].videoCode ? docData[`${itemDetails.type}`][uniqueId].videoCode : youtube_parser(itemDetails.link)
                }
                if (!docData[`${itemDetails.type}`]) {
                    docData[`${itemDetails.type}`] = {}
                }
                docData[`${itemDetails.type}`][uniqueId] =
                {
                    name: itemDetails.name,
                    id: uniqueId,
                    link: itemDetails.link,
                    description: itemDetails.description
                }
                if (itemDetails.type === ItemCategories.VIDEOS) {
                    docData[`${itemDetails.type}`][uniqueId].videoCode = itemDetails.videoCode
                }
                doc.exists ? transcation.update(docRef, docData) : transcation.set(docRef, docData);
                return
            })
            resolve();
        } catch (err) {
            reject(err)
        }
    });
}

export const deleteItem = (stallId, itemDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (stallId) {
                stallId = stallId.toLowerCase();
            }
            let docRef = firestore.collection(AppString.BACKSTAGE).doc(`${stallId}`)
            await firestore.runTransaction(async (transcation) => {
                const doc = await transcation.get(docRef);
                let docData = {}
                if (doc.exists) {
                    docData = doc.data();
                }
                let uniqueId = itemDetails.id;
                if (!docData[`${itemDetails.type}`]) {
                    let error =
                    {
                        code: "ItemTypeNotFoud",
                        message: "Such Item Doesn't Exists"
                    }
                    throw error;
                }
                if (!docData[`${itemDetails.type}`][uniqueId]) {
                    let error =
                    {
                        code: "ItemNotFoud",
                        message: "Such Item Doesn't Exists"
                    }
                    throw error;
                }
                if (itemDetails.type === ItemCategories.IMAGES || itemDetails.type === ItemCategories.PDF) {
                    await deleteImageFileUsingURL(docData[`${itemDetails.type}`][uniqueId].link)
                }
                delete docData[`${itemDetails.type}`][uniqueId];
                delete docData[`${itemDetails.type}Index`].splice(docData[`${itemDetails.type}Index`].indexOf(uniqueId), 1);

                if (Object.keys(docData[`${itemDetails.type}`]).length === 0) {
                    delete docData[`${itemDetails.type}`]
                }
                if (docData[`${itemDetails.type}Index`].length === 0) {
                    delete docData[`${itemDetails.type}Index`]
                }
                transcation.update(docRef, {
                    [`${itemDetails.type}`]: docData[`${itemDetails.type}`] ? docData[`${itemDetails.type}`] : firebase.firestore.FieldValue.delete(),
                    [`${itemDetails.type}Index`]: docData[`${itemDetails.type}Index`] ? docData[`${itemDetails.type}Index`] : firebase.firestore.FieldValue.delete()
                })
                return true;
            })
            resolve();
        } catch (err) {
            console.log(err)
            reject(err)
        }
    });
}

//#endregion

//region test
// TestCode.testAddEventSlot();
// TestCode.testaddDaySlot();
// TestCode.testaddWeekSlot();
// TestCode.testtoggleEventSlot();
// TestCode.testtoggleDateSlots();
// TestCode.testaddItems();
// TestCode.testupdateItem();
// TestCode.testdeleteItem();

//#endregion


const getWeekIdFromDate = (eventId) => {
    const weekendObject = getWeekEndsByWeek(new Date(eventId))
    var weekId = null;
    console.log(weekendObject)
    Object.keys(weekendObject).forEach(key => {
        let newArray = weekendObject[key];
        newArray.forEach(dayValue => {
            const a = new Date(dayValue)
            if (eventId === a.getTime()) {
                weekId = key;
            }
        })
    })
    console.log(weekId)
    return weekId;
}
const setWeekID = async () => {
    const colRef = firestore.collection("eventSlots")
    const snapShot = await colRef.get();
    snapShot.docs.forEach(async doc => {
        const weekId = getWeekIdFromDate(doc.data().dateTimestamp)
        console.log(doc.id, doc.data().dateTimestamp, weekId)
        firestore.collection("eventSlots").doc(doc.id).update({
            weekId: weekId
        })
    })
}
// setWeekID();

export const checkForDashboardAdminId = (email) => {
    return new Promise(async (res, rej) => {
        try {
            const doc = await firestore.collection('meetingRoom').doc('analyticsDashboard').get();
            if (doc.exists) {
                const data = doc.data().admins;
                console.log(data)
                if (data.indexOf(email) !== -1) {
                    res();
                }
            }
            let er = {
                code: "Non-AdminID", message: 'Please Admin ID'
            }
            throw (er)
        } catch (error) {
            rej(error)
        }
    })
}