import React, { Component, createContext } from "react";
import UserContext from "./UserContext";
import { auth, signOut, firebaseApp, realDB, checkForDashboardAdminId } from "../../firebase/firebase";

class UserProvider extends Component {

    state = {
        user: null
    };

    componentDidMount = async () => {
        auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                checkForDashboardAdminId(userAuth.email).then(() => {
                    this.setState({ user: userAuth });
                }).catch(err => {
                    console.log(err)
                    signOut();
                })
            } else {
                this.setState({
                    user: null
                })
            }
        });
    };

    render() {
        const { user } = this.state;

        return (
            <UserContext.Provider value={user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserProvider;
