import React, { Component, useContext } from "react";
import { UserContext } from "../../auth/providers";
import Application from "../../application/Application";
import $, { contains } from 'jquery';
import select2 from 'select2';
import Time from "../time/Time";
import Moment from "moment";
import { addEventSlot, addWeekSlot, getSlots, getWeekEndsByWeek, updateSlot } from "../../firebase/firebase";
import Analytics from "../analytics/Analytics";

const monthNames = [
    { label: "October", value: 9, year: 2020 },
    { label: "November", value: 10, year: 2020 },
    { label: "December", value: 11, year: 2020 },
    { label: "January", value: 0, year: 2021 },
    { label: "February", value: 1, year: 2021 },
    { label: "March", value: 2, year: 2021 },
    { label: "April", value: 3, year: 2021 },
    { label: "May", value: 4, year: 2021 },
    { label: "June", value: 5, year: 2021 },
    { label: "July", value: 6, year: 2021 },
    { label: "August", value: 7, year: 2021 },
    { label: "September", value: 8, year: 2021 },
    { label: "October", value: 9, year: 2021 },
    { label: "November", value: 10, year: 2021 },
    { label: "December", value: 11, year: 2021 },
];

const weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const month = new Array();
month[0] = "January";
month[1] = "February";
month[2] = "March";
month[3] = "April";
month[4] = "May";
month[5] = "June";
month[6] = "July";
month[7] = "August";
month[8] = "September";
month[9] = "October";
month[10] = "November";
month[11] = "December";

class Event extends Component {

    months = React.createRef();

    getCurrentMonthName = () => {
        let monthIndex = (new Date().getMonth());
        let yearIndex = (new Date().getFullYear());
        let result = 0;
        console.log(monthIndex, yearIndex)
        monthNames.forEach((element, index) => {
            if (element.value === monthIndex) {
                if (element.year === yearIndex) {
                    console.log(index)
                    result = index
                }
            }
        });

        return monthNames[result];
    }

    getMonthIndex = (month) =>{
        let monthIndex = month.value;
        let yearIndex = month.year;
        let result = 0;
        console.log(monthIndex, yearIndex)
        monthNames.forEach((element, index) => {
            if (element.value === monthIndex) {
                if (element.year === yearIndex) {
                    console.log(index)
                    result = index
                }
            }
        });

        return result;
    }

    state = {
        monthNames: monthNames,
        weeks: {},
        slots: {},
        selectedMonth: this.getCurrentMonthName(),
        events: null,
        showTimeSelect: false,
        currentTime: null,
        currentEvent: null,
        showAnalytics: false
    }

    componentDidMount() {
        $(this.months.current).select2({ minimumResultsForSearch: -1 }).on('change', (event) => {
            event.preventDefault();
            this.setState({ selectedMonth: monthNames[event.target.value] });
            this.getWeeks();
        });

        this.getWeeks();

    }

    getWeeks = () => {
        const weeks = getWeekEndsByWeek(new Date(this.state.selectedMonth.year, this.state.selectedMonth.value, 1));

        getSlots(Object.keys(weeks)).then(values => {

            let groupBy1 = this.groupBy(values.filter(value => {
                return new Date(value.monthTimestamp).getMonth() == this.state.selectedMonth.value
            }), "weekId");
            console.log(groupBy1);
            this.setState({ slots: groupBy1 });
        })
        this.setState({ weeks: weeks });
    }

    groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    addSlotsForTheWeek = (event, week, slotDates) => {
        const selectedMonth = this.state.selectedMonth;
        let days = {}
        slotDates.map(date => days[weekday[date.getDay()].toLowerCase()] = date.getDate())
        addWeekSlot({ month: selectedMonth.value, year: selectedMonth.year, weekId: week }, days).then(value => {
            this.getWeeks();
        });
    }

    showEvents = (event, events, value, week) => {
        event.preventDefault();
        let time = new Date(`${value.getMonth() + 1} ${value.getDate()}, ${value.getFullYear()} 00:00:00`).getTime()
        console.log(time);
        console.log(week);
        console.log(events.filter((e) => e.dateTimestamp == time));
        this.setState({ events: events.filter((e) => e.dateTimestamp == time), week: week })
    }

    resetEvents = (event) => {
        this.getWeeks()
        this.setState({ events: null, week: null });
    }

    showTimeSelect = (date, event) => {
        this.setState({ showTimeSelect: true });
        var amOrPm = (date.getHours() < 12) ? "AM" : "PM";
        let currentTime = { hour: date.getHours() % 12 || 12, minute: date.getMinutes(), indicator: amOrPm };
        console.log(currentTime)
        this.setState({ currentTime: currentTime });
        this.setState({ currentEvent: event });
    }

    convertTime12to24 = (hours, indicator) => {

        if (hours == 12) {
            hours = 0;
        }

        if (indicator == 'PM') {
            hours = hours + 12;
        }

        return hours;
    }

    hideTimeSelect = (time) => {
        let currentEvent = this.state.currentEvent;
        let date = new Date(currentEvent.eventStartTime);
        date.setHours(this.convertTime12to24(time.hour, time.indicator));
        date.setMinutes(time.minute);
        currentEvent.eventStartTime = date.getTime();

        updateSlot({ id: currentEvent.id, eventStartTime: date.getTime() }).then(value => {
            this.setState({ showTimeSelect: false, currentTime: null, currentEvent: null });
        });

    }

    closeTimeSelect = (time) => {
        this.setState({ showTimeSelect: false });
        this.setState({ currentTime: null });
    }

    onToggle = (event, eventDetails, index) => {
        event.preventDefault();

        updateSlot({ id: eventDetails.id, active: !eventDetails.active }).then(value => {
            eventDetails.active = !eventDetails.active;
            let events = this.state.events;
            events[index] = eventDetails;
            this.setState({ events: events });
        });

    }

    showAnalytics = (event, eventDetails) => {
        event.preventDefault();
        console.log(eventDetails)
        this.setState({ currentEvent: eventDetails });
        this.setState({ showAnalytics: true })
    }

    onBackClick = (event, eventDetails) => {
        event.preventDefault();
        this.setState({ showAnalytics: false })
    }

    render() {
        return (<>
            <div className={`main-container sidebar-active min-height-full ${this.state.showAnalytics ? 'd-none' : ''}`}>

                <div className={`dashboard__header ${this.state.events != null ? 'd-none' : ''}`}>

                    <h2 className="dashboard__header__title">Choose Your Month</h2>
                    <div className="d-flex align-items-center">
                        <div className="custom-dropdown mg-l30">
                            <select ref={this.months} className="js-example-basic-single"
                                defaultValue={ 
                                    this.getMonthIndex(this.state.selectedMonth)
                                    // this.state.selectedMonth.value
                                    }>
                                {
                                    this.state.monthNames.map((option, index) =>
                                        <option key={index} value={index}> {option.label} {option.year}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="dashboard__header">
                    <ul className="bradcrumb">
                        <li onClick={event => this.resetEvents(event)}>
                            {this.state.selectedMonth && this.state.selectedMonth.label + ' ' + this.state.selectedMonth.year}
                        </li>
                        {this.state.week &&
                            <li className="active"><a href="#">{this.state.week}</a></li>
                        }

                    </ul>
                </div>

                <div className="slots-wrapper">
                    {this.state.events == null &&
                        Object.keys(this.state.weeks).map((week, inx) =>
                            <>
                                {
                                    this.state.slots[week] &&
                                    <div key={week} className="slot-item">
                                        <div className="slot-item__inner">
                                            <div className="slot-item__header">
                                                Week {inx + 1}
                                            </div>

                                            <div className="slot-item__body">
                                                {
                                                    this.state.weeks[week].map((value, index) =>
                                                        <div key={index} className="slot-item__row">
                                                            <button className="btn btn-dark"
                                                                onClick={event => this.showEvents(event, this.state.slots[week], value, `Week ${inx + 1}`)}>
                                                                {weekday[value.getDay()] + ", " + month[value.getMonth()] + " " + value.getDate()}
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
{/* 
                                {
                                    !this.state.slots[week] &&
                                    <div key={inx} className="slot-item empty"
                                        onClick={event => this.addSlotsForTheWeek(event, week, this.state.weeks[week])}>
                                        <span className="slot-item__title">+ Add Week {inx + 1}</span>
                                    </div>
                                } */}

                            </>
                        )
                    }

                    {this.state.events && this.state.events.map((event, index) =>
                        <div className="slot-item">
                            <div className="slot-item__inner">
                                <div className="slot-item__header d-flex justify-content-between">
                                    {/* {`${new Date(parseInt(event.id.split('-')[0])).toDateString()}-${event.id.split('-')[1]}`} */}
                                    {`${event.id.split('-')[1]}`}
                                    {/* <label className="custom-toggle">
                                        <input type="checkbox"
                                            onChange={event1 => this.onToggle(event1, event, index)}
                                            checked={event.active} />
                                        <div className="slider round"></div>
                                    </label> */}
                                </div>
                                <div className="slot-item__body">
                                    {/* <div className="slot-item__row">
                                        <button className="btn btn-dark has-icon" disabled={!event.active}>
                                            {Moment(new Date(event.eventStartTime)).format('MM-DD-YYYY, h:mm:ss a')}
                                            <span className="icon-timer"
                                                onClick={event1 => this.showTimeSelect(new Date(event.eventStartTime), event)}></span>
                                        </button>
                                    </div> */}
                                    <div className="slot-item__row">
                                        <button className="btn btn-dark"
                                            onClick={evt => this.showAnalytics(evt, event)}>ANALYTICS
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>

            </div>

            {
                this.state.showAnalytics &&
                <Analytics
                    onBackClick={event => this.onBackClick(event)}
                    date={this.state.selectedMonth.label + ' ' + this.state.selectedMonth.year}
                    week={this.state.week}
                    event={this.state.currentEvent}>
                </Analytics>
            }
            {
                this.state.showTimeSelect &&
                <Time time={this.state.currentTime} onTimeUpdate={time => this.hideTimeSelect(time)} onClose={time => this.closeTimeSelect(time)}></Time>
            }
        </>);
    }

}

Event.contextType = UserContext;

export default Event;
